import React, { useEffect } from 'react'
import Top from 'src/components/pages/Top'
import { DefaultLayout } from 'src/components/layout/default'
import { Head } from 'src/components/layout/head'

const IndexPage: React.FCX = () => {
  useEffect(() => {
    window.location.href = 'https://smooth.jp?redirectedFrom=corp'
  }, [])

  return (
    <div className="index-page">
      {/* <Head />
      <DefaultLayout smoothScroll>
        <Top />
      </DefaultLayout> */}
    </div>
  )
}

export default IndexPage
